// // Define the menu structure type
// export interface SubMenu {
//     label: string;
//     link: string;
//   }

//   export interface Menu {
//     id: string;
//     label: string;
//     link?: string;
//     subMenu?: SubMenu[];
//   }

//   // Sample menu data
//   export const menuData: Menu[] = [
//     {
//       id: "dashboard",
//       label: "Dashboard",
//       link: "/"
//     },
//     {
//       id: "documents",
//       label: "Document Editor",
//       link: "/documentEditor",
//       subMenu: [
//         { label: "Add Print Card Data", link: "/addPrintCard" },
//         { label: "Print Card List", link: "/printCardList" },
//       ]
//     },
//     {
//       id: "projects",
//       label: "Projects",
//       subMenu: [
//         { label: "Current Projects", link: "/projects/current" },
//         { label: "Completed Projects", link: "/projects/completed" },
//         { label: "Inhouse Projects", link: "/projects/inhouseprojects" }
//       ]
//     },
//     {
//       id: "bills",
//       label: "Bills",
//       link: "/bills"
//     },
//     {
//       id: "tasks",
//       label: "Tasks",
//       subMenu: [
//         { label: "Pending Tasks", link: "/tasks/pending" },
//         { label: "Completed Tasks", link: "/tasks/completed" }
//       ]
//     },
//     {
//       id: "reports",
//       label: "Reports & Analytics",
//       subMenu: [
//         { label: "Sales Reports", link: "/reports/sales" },
//         { label: "Finance Reports", link: "/reports/finance" },
//         { label: "Traffic Analytics", link: "/reports/traffic" }
//       ]
//     }
//   ];

// Define the menu structure type
export interface SubMenu {
  label: string;
  link: string;
}

export interface Menu {
  id: string;
  label: string;
  link?: string;
  subMenu?: SubMenu[];
}

// Sample menu data
export const menuData: Menu[] = [
  { id: "listmember", label: "Member List & Print", link: "/" },
  { id: "addmember", label: "Add Member", link: "/add" },
];
