import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Menu from './components/Menu';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App: React.FC = () => {
  return (
    <Router>
      <div className="min-h-screen bg-gray-100">
        {/* Add console log to see if the component is rendering */}
        <Sidebar />
        <div className="relative ml-[250px]">
          <Menu />
        </div>
      </div>
      <ToastContainer />
    </Router>
  );
};

export default App;
