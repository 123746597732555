import { Route, Routes } from 'react-router-dom'
import AddPrintCard from '../pages/document/addprintcard'
import PrintCardList from '../pages/document/printcardlist'

const Menu = () => {
    return (
        <Routes>
            <Route path="/" element={<PrintCardList />} />
            <Route path="/add" element={<AddPrintCard />} />
        </Routes>
    )
}

export default Menu