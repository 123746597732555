import React, { useRef } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { NTUCMemberList } from "../../services/ntuc";

const PrintCards = ({ ntucmembers }: { ntucmembers: NTUCMemberList[] }) => {
    const printRef = useRef<HTMLDivElement>(null);
    const [topSpace, setTopSpace] = React.useState(0);
    const [generatingStatus, setGeneratingStatus] = React.useState(false);
    const exportToPDF = async () => {
        if (printRef.current) {
            setGeneratingStatus(true);
          const element = printRef.current;
      
          // Render the section to a canvas
          const canvas = await html2canvas(element, {
            scale: 2,
            useCORS: true, // Enable cross-origin for images
          });
          const data = canvas.toDataURL("image/png");
      
          // Create a PDF
          const pdf = new jsPDF({
            orientation: "portrait",
            unit: "mm",
            format: "a4",
          });
      
          // Calculate dimensions
          const pageHeight = 297; // A4 height in mm
          const margin = -3; // Add a margin of 10mm
          const imgWidth = 210 - margin * 2;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          let heightLeft = imgHeight;
          let position = margin;
          
          while (heightLeft > 0) {
            pdf.addImage(data, "PNG", margin, position, imgWidth, imgHeight);
            heightLeft -= pageHeight - margin * 2;
            if (heightLeft > 0) {
              pdf.addPage();
              position = margin; // Reset position for new page
            }
          }
      
          // Save the PDF
          pdf.save(`${Date.now()}.pdf`);
      
          setGeneratingStatus(false);
        }
      };
      
    return (
        <div>
            <div className="flex justify-end">
                {generatingStatus && <button
                 
                    className="bg-blue-500 text-white py-2 px-4 rounded-md"
          
                >
                    Generating...
                </button>}
                {!generatingStatus && <button
                    onClick={exportToPDF}
                    className="bg-blue-500 text-white py-2 px-4 rounded-md"
                    onMouseEnter={() => setTopSpace(8)}
                    onMouseLeave={() => setTopSpace(0)}
                >
                    Export Card
                </button>}
            </div>
            <div className=" left-[260px] right-0 top-[210px] bg-white w-[1400px] fixed opacity-0">
                {/* PDF Export Button */}


                {/* Cards Section */}
                <div ref={printRef} className="grid grid-cols-3 gap-2 ">
                    {ntucmembers.map((ele, index) => (
                        <div key={ele._id} className=" relative w-[480px] h-[640px]">
                            <img
                                src="https://app.upliftsolutions.com.np/api/uploads/gallery/images/compressed/dt6-1734179054029.jpeg"
                                alt={ele.name}
                                className="h-[640px] object-contain m-auto"
                            />
                            <img src={ele.media.mediaUrl} alt="" className={`absolute left-[212px] top-[150px] w-[70px]`} />
                            <span className={`absolute right-[92px]  text-[14px] text-[#fff] font-medium`} style={{ top: `${70 - topSpace}px` }}>
                                {ele.roll}
                            </span>
                            <span className={`absolute left-[220px] text-[20px] text-[#40428C] font-medium`} style={{ top: `${337 - topSpace}px` }}>
                                {ele.name}
                            </span>
                            <span className={`absolute left-[220px]  text-[20px] text-[#40428C] font-medium`} style={{ top: `${362 - topSpace}px` }}>
                                {ele.district}
                            </span>
                            <span className={`absolute left-[220px] text-[20px] text-[#40428C] font-medium`} style={{ top: `${388 - topSpace}px` }}>
                                {ele.agency}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </div>

    );
};

export default PrintCards;
