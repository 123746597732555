import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { menuData } from '../services/menu';


const Sidebar: React.FC = () => {
  const location = useLocation();
  const [openMenu, setOpenMenu] = useState<string | null>(null);

  // Detect active menu and submenu on location change
  useEffect(() => {
    const activeMenu = menuData.find(
      (menu) =>
        menu.link === location.pathname ||
        menu.subMenu?.some((sub) => sub.link === location.pathname)
    );
    if (activeMenu) {
      setOpenMenu(activeMenu.id);
    }
  }, [location.pathname]);

  // Check if a link is active
  const isActive = (path: string) => location.pathname === path;

  return (
    <div className="h-screen w-64 bg-[#5251AF] text-white fixed top-0 left-0 flex flex-col">
      <div className="flex flex-col gap-2 py-4 items-center justify-center  border-b border-white">
        <img src="https://ntuc.org.np/application/resources/site//img/ntuclogo.jpg" alt="" className='w-[100px]' />
        <h1 className="text-2xl font-bold">NTUC Portal</h1>
      </div>

      <nav className="flex-grow mt-4">
        <ul className="space-y-2 px-4 max-h-screen overflow-auto">
          {menuData.map((menu) => (
            <li key={menu.id}>
              {menu.link ? (
                <Link
                  to={menu.link}
                  className={`block py-2 px-4 rounded transition duration-300 ${isActive(menu.link) ? "bg-[#ED202A] text-white" : "hover:bg-[#ED202A]"
                    }`}
                >
                  {menu.label}
                </Link>
              ) : (
                <button
                  onClick={() =>
                    setOpenMenu((prev) => (prev === menu.id ? null : menu.id))
                  }
                  className={`block w-full text-left py-2 px-2 rounded transition duration-300 ${openMenu === menu.id ? "bg-[#ED202A] text-white" : "hover:bg-[#ED202A]"
                    }`}
                >
                  {menu.label}
                </button>
              )}
              {menu.subMenu && openMenu === menu.id && (
                <div className="mt-2 pl-4">
                  <ul className="space-y-1 border-l-2 border-gray-600">
                    {menu.subMenu.map((sub) => (
                      <li key={sub.link}>
                        <Link
                          to={sub.link}
                          className={`block py-2 px-4 transition duration-300 ${isActive(sub.link) ? "bg-[#ED202A] text-white" : "hover:bg-[#ED202A]"
                            }`}
                        >
                          {sub.label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </li>
          ))}
        </ul>
      </nav>

     
    </div>
  );
};

export default Sidebar;
