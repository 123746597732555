import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PrintCards from "../../components/document/PrintCard";
import { deleteNTUCMember, District, getNepalAddress, getNTUCMembers, NTUCAddressResult, NTUCMemberList, Pagination } from "../../services/ntuc";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

export interface Project {
    id: number;
    title: string;
    status: string;
}

const tabs = [
    {
        id: 1,
        label: "Table View"
    }
]

const PrintCardList: React.FC = () => {
    const navigate = useNavigate();
    const [filteredStatus, setFilteredStatus] = useState<string>("All");
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [activeTab, setActiveTab] = useState<number>(1);
    const itemsPerPage = 3;
    const [members, setMembers] = useState<NTUCMemberList[]>();
    const [memberPagination, setMemberPagination] = useState<Pagination>();
    const [province, setProvince] = useState<string>(""); // Nepali converted agency
    const [provinces, setProvinces] = useState<NTUCAddressResult>(); // Nepali converted province
    const [districts, setDistricts] = useState<District[]>([]);
    const [district, setDistrict] = useState<string>(""); // Nepali converted district
    const [limit, setLimit] = useState<number>(10);


    // Handle edit
    const handleEdit = (id: number) => {
        alert(`Edit Project with ID: ${id}`);
    };
    const handleSingle = (id: number) => {
        navigate(`/projects/inhouseprojects/${id}`);
    };

    useEffect(() => {
        (async () => {
            // const resultProvince = await getNepalAddress();
            // setProvinces(resultProvince);
            const resultNTUCMember = await getNTUCMembers({ page: 1, limit: limit });
            setMemberPagination(resultNTUCMember.data.pagination);
            setMembers(resultNTUCMember.data.results);
        })()
    }, [limit]);
    const prevPage = async () => {
        await getNTUCMembers({ page: currentPage - 1 });
        setCurrentPage(currentPage - 1);
    }

    const nextPage = async () => {
        await getNTUCMembers({ page: currentPage + 1 });
        setCurrentPage(currentPage + 1);
    }

    const handleDelete = async (id: string) => {
        if (window.confirm('Are you sure you want to delete this member?')) {
            const response = await deleteNTUCMember(id);
            if (response.status === 200) {
                toast.success(response.data.message);
                setMemberPagination(response.data.pagination);
                setMembers(response.data.results);
            } else {
                toast.error(response.data.message);
            }
        }
    }


    return (
        <div className="p-10">
            <h1 className="text-2xl font-bold mb-4">NTUC Members</h1>
            <div className="flex flex-col gap-3 mb-4">
                <label htmlFor="statusFilter" className="block mb-2 font-medium">
                    Increase No. of Members:
                </label>
                <select
                    id="statusFilter"
                    value={limit}
                    onChange={(e) => setLimit(parseInt(e.target.value))}
                    className="border border-gray-300 rounded px-4 py-2 w-[300px]  h-10"
                >
                    <option value="10">10</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                </select>
            </div>
            {/* Filter Dropdown */}
            {false && <div className="flex gap-3 mb-4">
                {/* Name Filter */}
                <div>
                    <label htmlFor="statusFilter" className="block mb-2 font-medium">
                        Filter by Name:
                    </label>
                    <input type="text" className="border border-gray-300 rounded px-4 py-2 w-[300px] h-10" />
                </div>
                {/* District Filter */}
                <div>
                    <label htmlFor="statusFilter" className="block mb-2 font-medium">
                        Filter by District:
                    </label>
                    <input type="text" className="border border-gray-300 rounded px-4 py-2 w-[300px] h-10" />
                </div>
                {/* Filter Dropdown */}
                <div>
                    <label htmlFor="statusFilter" className="block mb-2 font-medium">
                        Filter by Agency:
                    </label>
                    <select
                        id="statusFilter"
                        value={filteredStatus}
                        onChange={(e) => setFilteredStatus(e.target.value)}
                        className="border border-gray-300 rounded px-4 py-2 w-[300px]  h-10"
                    >
                        <option value="All">All</option>
                        <option value="Active">Active</option>
                        <option value="Completed">Completed</option>
                        <option value="On Hold">On Hold</option>
                    </select>
                </div>
            </div>}
            <div className="flex justify-between gap-3 mb-4">
                <ul className="flex gap-3">
                    {tabs.map((ele, index) => <li key={index} className={` px-3 py-2 cursor-pointer ${activeTab === ele.id ? "bg-blue-200" : "bg-white"}`} onClick={() => setActiveTab(ele.id)}>{ele.label}</li>)}
                </ul>

                <ul className="flex items-center gap-2">
                    <li> <Link to="/add" className="bg-[#ED202A] text-white block h-10 py-2 px-4 rounded-md">Add Member</Link></li>
                    <li>
                        {members && <PrintCards ntucmembers={members} />}
                    </li>
                </ul>
            </div>

            {activeTab === 1 &&
                <div>
                    {/* Table */}
                    <div className="overflow-x-auto">
                        <table className="min-w-full border-collapse">
                            <thead>
                                <tr>
                                    <th className="px-4 py-2 border-b text-left">फोटो</th>
                                    <th className="px-4 py-2 border-b text-left">क्रम संख्या</th>
                                    <th className="px-4 py-2 border-b text-left">नाम</th>
                                    <th className="px-4 py-2 border-b text-left">प्रदेश</th>
                                    <th className="px-4 py-2 border-b text-left">जिल्ला</th>
                                    <th className="px-4 py-2 border-b text-left">आवद्ध संघ</th>
                                    <th className="px-4 py-2 border-b text-left">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {members && members.map((ele, index) => (
                                    <tr key={ele._id}>
                                        <td className="px-4 py-2 border-b"><img src={ele.media.mediaUrl} alt="" className="w-[50px] h-[50px]" /></td>
                                        <td className="px-4 py-2 border-b">{ele.roll}</td>
                                        <td className="px-4 py-2 border-b">{ele.name}</td>
                                        <td className="px-4 py-2 border-b">{ele.province}</td>
                                        <td className="px-4 py-2 border-b">{ele.district}</td>
                                        <td className="px-4 py-2 border-b">{ele.agency}</td>
                                        <td className="px-4 py-2 border-b">
                                            <IconButton onClick={() => handleDelete(ele._id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </td>

                                    </tr>
                                ))}
                                {memberPagination && memberPagination.total === 0 && (
                                    <tr>
                                        <td
                                            colSpan={7}
                                            className="px-4 py-2 border-b text-center text-gray-500"
                                        >
                                            No members found.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                    {/* Pagination */}
                    <div className="flex justify-between items-center mt-4">
                        <button
                            onClick={prevPage}
                            disabled={memberPagination && !memberPagination.previous}
                            className="px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded disabled:opacity-50"
                        >
                            Previous
                        </button>
                        <span>
                            Page {currentPage} of {memberPagination ? Math.ceil(memberPagination.total / 10) : 1}
                        </span>
                        <span>
                            Total Members: {memberPagination ? memberPagination.total : 0}
                        </span>
                        <button
                            onClick={nextPage}
                            disabled={memberPagination && !memberPagination.next}
                            className="px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded disabled:opacity-50"
                        >
                            Next
                        </button>
                    </div>
                </div>
            }


        </div >
    );
};

export default PrintCardList;
